<template>
  <div>
    <v-sheet>
      <v-card>
        <v-card-title>Турнирная таблица</v-card-title>
      </v-card>
      <v-simple-table class="mе-4 mb-4 ml-0 mr-0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Место</th>
              <th class="text-left">Команда</th>
              <th class="text-left">Раунд 1</th>
              <th class="text-left">Раунд 2</th>
              <th class="text-left">Раунд 3</th>
              <th class="text-left">Раунд 4</th>
              <th class="text-left">Финал</th>
              <th class="text-left">Итого</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in teams" :key="item.id">
              <td>{{ item.index }}</td>
              <td style="text-transform: capitalize">{{ item.id }}</td>
              <td>{{ item.kahoot }}</td>
              <td>{{ item.roundTwo }}</td>
              <td>{{ item.musiclogic }}</td>
              <td>{{ item.team }}</td>
              <td>{{ item.final }}</td>
              <td>{{ item.total }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "RocheStandings",
  props: ["items", "my-points", "my-game-id"],
  computed: {
    ...mapGetters({
      meta: "event/eventMeta",
    }),
    teams() {
      const teamsByIDs = _.reduce(
        this.items,
        (acc, item) => {
          const { tags } = item;
          const teamTag = _.find(tags, (tag) => /команда\s+\d+/gi.test(tag));
          if (teamTag) {
            if (acc[teamTag] == null) {
              acc[teamTag] = {
                items: [],
                points: 0,
                personal: 0,
                team: 0,
                final: 0,
              };
            }
            acc[teamTag].items.push(item);
            acc[teamTag].points += item.points;
            if (item.final) acc[teamTag].final += item.final;
            acc[teamTag].team += item.team;
          }
          return acc;
        },
        _.times(25, (i) => i).reduce((acc, index) => {
          acc[`команда ${index + 1}`] = {
            items: [],
            points: 0,
            personal: 0,
            team: 0,
            final: 0,
          };
          return acc;
        }, {})
      );
      const result = _.map(
        _.orderBy(
          Object.keys(teamsByIDs).map((teamKey) => {
            const { team, final } = teamsByIDs[teamKey];
            // const personal = _.slice(
            //   _.orderBy(items, ["personal"], ["desc"]),
            //   0,
            //   3
            // ).reduce((acc, item) => acc + item.personal, 0);
            const musiclogic = this.musiclogic[teamKey] || 0;
            const kahoot = this.kahoot[teamKey] || 0;
            const quiz = this.quiz[teamKey] || 0;
            const roundTwo = this.roundTwo[teamKey] || 0;
            const result = {
              id: teamKey,
              kahoot, // раунд 1 - Kahoot
              roundTwo, // раунд 2 - топ 3 в личных заданиях
              musiclogic, // раунд 3 - музыкальная логика
              team, // раунд 4 - сумма за командные задания
              final: final + quiz, // раунд 5 - финальное задание
              total: team + roundTwo + final + musiclogic + kahoot + quiz, // Сумма всех раундов
            };
            return result;
          }),
          ["total"],
          ["desc"]
        ),
        (item, index) => {
          return {
            ...item,
            index: index + 1,
          };
        }
      );
      return result;
    },
    kahoot() {
      return (this.meta && this.meta.kahoot) || {};
    },
    musiclogic() {
      return (this.meta && this.meta.musiclogic) || {};
    },
    quiz() {
      return (this.meta && this.meta.quiz) || {};
    },
    roundTwo() {
      return (this.meta && this.meta.roundTwo) || {};
    },
  },
};
</script>
